export const getCategoryForEvent = (
  itemCategorySlugs: string[],
  eventCategorySlugs: string[],
) => {
  const eventCategory = itemCategorySlugs.find((category) =>
    eventCategorySlugs.includes(category),
  );
  return eventCategory ?? eventCategorySlugs[0];
};

export const addUTMToEventLink = (link: string, utmMedium: string) => {
  try {
    const url = new URL(link);
    url.searchParams.set('utm_source', 'site');
    url.searchParams.set('utm_medium', utmMedium);
    return url.toString();
  } catch {
    return link;
  }
};
